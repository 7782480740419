export const gerarDest = (operacao, destinatario) => {
	let dest = undefined
	let cnpj = destinatario.cpfCnpj.length === 14 ? destinatario.cpfCnpj : undefined
	let cpf = destinatario.cpfCnpj.length < 14 && destinatario.cpfCnpj ? destinatario.cpfCnpj : undefined

	if (cnpj || cpf) {
		dest = { 
			id: operacao === "ALTERAR" ? destinatario.id : undefined,
			CNPJ: cnpj,
			CPF: cpf
		}	
	}

  return dest
}