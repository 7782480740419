import React, { useEffect, useState } from 'react';
import './style.css';
import { useNavigate } from "react-router-dom";
import { Configuracao } from "../../configuracao";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { DialogActions, IconButton, useMediaQuery } from '@mui/material';
import EmpresaConfig from "../../pages/EmpresaConfig";
import EmpresaModal from '../../components/EmpresaModal';
import Foguete from '../../assets/foguete.svg';
import api from '../../services/api';

function PassoAPasso() {
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const [step, setStep] = useState(1);
    
  const [empresaModalOpen, setEmpresaModalOpen] = useState(false);
  const handleOpenModal = () => { setEmpresaModalOpen(true); }
  const navigate = useNavigate();
  const handleNavigateTo = (path) => { navigate(path); };

  const handleCloseEmpresaModal = () => {
    setEmpresaModalOpen(false)
    avancarPasso()
  }    

  const handleButtonClick = (botaoClicado) => {
    if (!botaoClicado) {
      voltarPasso()
    }
  };

  const avancarPasso = () => {
    if (step === 3) {
      document.getElementById("empresaConfig_save").click()
    }
    else if (step === 4) {
      handleNavigateTo("/")
      window.location.reload()      
    }
    else if (step < 4) {
      setStep(step + 1)
    }
  };

  const voltarPasso = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  };

  const handleClickPular = () => {
      setStep(step + 1)
  };

  useEffect(() => {
    if (step === 2) {
     handleOpenModal()
    }
  }, [step]);

  useEffect(() => {
    (async () => {
      try {
        const { data: dataEmpresa } = await api.get("/v1/empresas");
        if (!(dataEmpresa.empresas.length === 0)) {
          handleNavigateTo("/");
        }
      } catch (error) {
      }
    })();
  }, []);

  return (
    <div class="geral">
      <div class="cabecalho">
        <div class= {isSmallScreen 
                    ? "spaceInitial"
                    : ""} >
        </div>              
        {/* ********  1  ******** */}
        <div class= {isSmallScreen 
                    ? step === 1 ? "spaceName" : ""
                    : ""} 
        >
          { (isSmallScreen && step === 1) && ( <span class="name">Olá!</span>)}
        </div>       
        <div class= {isSmallScreen ? "iconGroupSmall": "iconGroup"}>
          <WavingHandOutlinedIcon
            style={{
              color: isSmallScreen ? Configuracao.COR_6 : Configuracao.COR_PRIMARY, 
              fontSize: isSmallScreen ? 30 : 70,
              marginLeft: isSmallScreen ? 12 : 0
            }} 
          />
            { !isSmallScreen && (
              <span style={{color: Configuracao.COR_PRIMARY}}>
                Olá!
              </span>
            )}
        </div>
        {/* ********  2  ******** */}
        <div class= {isSmallScreen 
                    ? step === 2 ? "spaceName" : ""
                    : step < 2 ? "risco": "risco2"} 
        >
          { (isSmallScreen && step === 2) && ( <span class="name">Empresa</span>)}
        </div>       
        <div class= {isSmallScreen 
                    ? step >= 2 ? "iconGroupSmall" : "iconGroupSmall2"
                    : "iconGroup"}
        >
          <StoreOutlinedIcon 
            style={{
              color: isSmallScreen 
                   ? Configuracao.COR_6 
                   : step >= 2 ? Configuracao.COR_PRIMARY : Configuracao.COR_5, 
              fontSize: isSmallScreen ? 30 : 80,
              marginLeft: isSmallScreen ? 12 : 0
            }} 
          />
            { !isSmallScreen && (
              <span style={{color: step >= 2 ? Configuracao.COR_PRIMARY : Configuracao.COR_5}}>
                Empresa
              </span>
            )}
        </div>
        {/* ********  3  ******** */}
        <div class= {isSmallScreen 
                    ? step === 3 ? "spaceName" : ""
                    : step < 3 ? "risco": "risco2"} >
          { (isSmallScreen && step === 3) && ( <span class="name">Ajustes</span>)}
        </div>       
        <div class= {isSmallScreen 
                    ? step >= 3 ? "iconGroupSmall" : "iconGroupSmall2"
                    : "iconGroup"}
        >
        <SettingsOutlinedIcon 
          style={{
            color: isSmallScreen 
            ? Configuracao.COR_6 
            : step >= 3 ? Configuracao.COR_PRIMARY : Configuracao.COR_5, 
            fontSize: isSmallScreen ? 30 : 80,
            marginLeft: isSmallScreen ? 12 : 0
          }} 
        />
          { !isSmallScreen && (
            <span style={{color: step >= 3 ? Configuracao.COR_PRIMARY : Configuracao.COR_5}}>
              Configurações
            </span>
          )}
        </div>
        {/* ********  4  ******** */}
        <div class= {isSmallScreen 
                    ? step === 4 ? "spaceName" : ""
                    : step < 4 ? "risco": "risco2"} >
          { (isSmallScreen && step === 4) && ( <span class="name">Finalizar</span>)}
        </div>       
        <div class= {isSmallScreen 
                    ? step === 4 ? "iconGroupSmall" : "iconGroupSmall2"
                    : "iconGroup"}
        >
          <CheckCircleOutlinedIcon
            style={{
              color: isSmallScreen 
                   ? Configuracao.COR_6 
                   : step >= 4 ? Configuracao.COR_PRIMARY : Configuracao.COR_5, 
              fontSize: isSmallScreen ? 30 : 80,
              marginLeft: isSmallScreen ? 12 : 0
            }} 
          />
          { !isSmallScreen && (
            <span style={{color: step >= 4 ? Configuracao.COR_PRIMARY : Configuracao.COR_5}}>
              Finalizar
            </span>
          )}
        </div>
      </div>

      <div class="body1">
          {step === 1 && (
            <div class="body">
              <img alt="comecar"
                id="vamosComecar"
                style={{ width: isSmallScreen ? "75%" : "30%", 
                         height: isSmallScreen ? "30%" : "45%" }}
                src={Foguete}
              /> 
              <br/>    
              <h1>Vamos começar!</h1>
              <li>Basta preencher os dados da sua empresa,</li>
              <li>e algumas configurações.</li>
            </div>
          )}

          {step === 2 && (
            <div class="body">
              <EmpresaModal        
                open={empresaModalOpen}
                onClose={handleCloseEmpresaModal}    
                onClick={handleButtonClick}
                empresaId={null}
              />
            </div>            
          )}
          
          {step === 3 && (
            <div class="body">
              <EmpresaConfig isWizard={true} setStep={setStep}/>
              <span onClick={handleClickPular}
                    style={{
                      // fontSize: 25,
                      textDecoration: "underline",
                      marginLeft: "-92px",
                      cursor: "pointer",
                    }}
              >
                Pular essa etapa
              </span>
            </div>
          )}

          {step === 4 && (
            <div class="body">
              <div>
                <SportsScoreIcon  style={{opacity: '0.3', fontSize: '8em'}}>
                </SportsScoreIcon>
              </div>
              <h1>Parabéns</h1>
              <li>As configurações iniciais foram concluídas!</li>
              <li>Bora usar a aplicação!?</li>
            </div>
          )}
      </div>

      <DialogActions class="rodape">
        {(step === 4) && (<IconButton onClick={voltarPasso}>
          <ArrowCircleLeftIcon
            style={{margin:'10px 10px 10px 20px',
                    fontSize: isSmallScreen ? 45 : 60,
                  }}
            color="primary"
          />
        </IconButton>
        )}
        {(step < 4) && (<div></div>)}

        <span class="passos">Passo {step}</span>

        <IconButton onClick={avancarPasso}>
          <ArrowCircleLeftIcon
            style={{margin:'10px 20px 10px 10px', 
                    transform: 'rotate(180deg)',
                    fontSize: isSmallScreen ? 45 : 60,
                  }}
            color="primary"
          />
        </IconButton>

      </DialogActions>

    </div>
  );
}

export default PassoAPasso;