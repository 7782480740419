import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, Container, IconButton, Typography, useMediaQuery } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError.js";
import { Configuracao } from "../../configuracao";
import api from "../../services/api";
import "./style.css"

import { LineChart } from '@mui/x-charts/LineChart';
import EmissaoModal from "../../components/Modal/EmissaoModal";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';           
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FlexMessage from "../../components/message/FlexMessage/index.js";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

const Main = ({ handleToggleMenu }) => {
  //  ***************
  //  ** Variables **
  //  ***************

  const urlPage = window.location.search;
  const urlParams = new URLSearchParams(urlPage);

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [ errorNota, setErrorNota ] = useState(false); 
  const [ mensageError, setMensageError ] = useState("");   
  const [ graficoDia, setGraficoDia ] = useState([]);
  const [ graficoMes, setGraficoMes ] = useState([]);  
  const [ typeGrafic, setTypeGrafic ] = React.useState(true);
  const [ emissaoModal, setOpenModalEmissao ] = useState(urlParams.get('emitirNfce') === '1');  
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const [idEmpresa, setEmpresa] = useState(-1);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    fetch('/version.txt')
      .then(response => response.text())
      .then(version => {
        if (version !== localStorage.getItem('frontend_version')) {
          localStorage.setItem('frontend_version', version);
          setIsUpdated(true);
        }
      });
  }, []);

  useEffect(() => {
    const fetchGraficos = async () => {
      try {
        const apiCalls = [
          api.get("v1/graficos-iniciais/"),
          api.get("/v1/empresas"),
        ];
        const [dataGrafico, dataEmpresa] = await Promise.all(apiCalls);

        setEmpresa(dataEmpresa.data.empresas[0]?.id);
        setGraficoMes(dataGrafico.data.mensal);
        setGraficoDia(dataGrafico.data.diaria);

      } catch (error) {
        toastError(error);
      }
    };
    fetchGraficos();
  }, [idEmpresa]);

  //  ***************
  //  ** Functions **
  //  ***************
  const handleNavigateTo = (path) => { navigate(path); };

  const handleOpenModalEmissao = async () => {       

    try {
      const { data } = await api.get(
        `/v1/empresas-configuracoes-payload/${idEmpresa}`,
      );
      
      if (!data.configuracaoNfce) {
       setMensageError("Configuração do NFC-e")
       setErrorNota("Para emissão da NFC-e, é necessário finalizar as configurações. \n Por favor, vá ao menu Configurações e preencha as informações.")
      } 
      else if (!data.certificado) {
        setMensageError("Informe o certificado")
        setErrorNota("Para emissão da NFC-e, é obrigatório informar o certificado digital. \n Por favor, vá ao menu Configurações e configure-o.")
      } 
      else {
        setOpenModalEmissao(true); 
      }
      
    } catch (error) {
      toastError(error);
    }   
  };

  const handleCloseModalEmissao = () => {
    setOpenModalEmissao(false);
  }

  const handleTypeGraficAnual = () => {
    setTypeGrafic(false)
  }
  const handleTypeGraficMensal = () => {
    setTypeGrafic(true)
  }

  if (isUpdated) {
    window.location.reload(true); // Força a atualização sem cache
  }

  //  ************
  //  ** Return **
  //  ************

  const mesAbreviado = (valor) => {    
    if (valor === null || valor === undefined) {
      throw new Error('O valor de mes/ano não pode ser nulo nem indefinido');
    }
    
    let resultadoFormatado = '';
    let mesAno = [];
    if (!typeGrafic) {
      const [ano, mes] = valor.split('-');  
      const mesesAbreviados = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      
      const nomeMesAbreviado = mesesAbreviados[parseInt(mes, 10) - 1];
      resultadoFormatado = `${nomeMesAbreviado}\n${ano}`;      
    } else { //diario      
      mesAno = valor.split('-');
      resultadoFormatado = `${mesAno[2]}/${mesAno[1]}`;
    }

    return resultadoFormatado;
  };

  return (
    <>
    {!idEmpresa && (handleNavigateTo("/wizard"))}

    <EmissaoModal
      open={emissaoModal}
      especie={"NFCE"}
      onClose={handleCloseModalEmissao}
      urlPage={urlPage}
    />    

    <FlexMessage
      open={errorNota}
      onClose={() => setErrorNota(false)}
      title={mensageError}
      tipo={"error"}
      message={errorNota}
    />

    <Container className="mainContainer">
      
      <Container className="information" style={isSmallScreen ? {height:"40vh"} : {height:"50vh"}}>
        
        <Container className={isSmallScreen ? "informationInitialCelular" : "informationInitialComputador"}>     
          {isSmallScreen && (
            <Container className="iconMenu">
              <IconButton onClick={handleToggleMenu}>
                <MenuOutlinedIcon 
                  style={{color: Configuracao.COR_SECUNDARY, fontSize: 35}} 
                />
              </IconButton>
            </Container>
          )}
          <Typography 
            style={{
              fontSize: isSmallScreen ? '12px' : '16px',              
              marginRight: '0em',
            }}>
              Bem-vindo <b>{user.name}</b>!
          </Typography>
          <Typography 
            style={{
              fontSize: isSmallScreen ? '16px' : '22px',               
              marginRight: '0em',
              fontWeight: 'bold'}}
          >
            Resumo das Vendas
          </Typography>
          
        </Container>
        
        <Container className="grafic">
          <Container className="graficOptions">
            <Button 
              variant="text" 
              onClick={handleTypeGraficMensal}
              style={{
                color: typeGrafic ? Configuracao.COR_4 : Configuracao.COR_PRIMARY, 
                left: 35,
                textTransform: 'capitalize'
              }}
            >
              Diário
            </Button>
            <Button
              variant="text" 
              onClick={handleTypeGraficAnual}
              style={{
                color: typeGrafic ? Configuracao.COR_PRIMARY : Configuracao.COR_4,
                left: 35,
                textTransform: 'capitalize'
              }}
            >
              Mensal
            </Button>
          </Container>
        <LineChart
          dataset={typeGrafic ? graficoDia : graficoMes}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: typeGrafic ? 'data' : 'anoMes',
              valueFormatter: (date, context) =>
                context.location === 'tick'
                  ? mesAbreviado(date)
                  : `Período  ${new Date(date).toLocaleDateString('pt-BR',{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
               })}`,
            },
          ]}
          margin={{ left: 55, 
            right: 20, 
            top: 10, 
            bottom: 44 }}
          series={[{ dataKey: 'valorTotal', color: Configuracao.COR_4, valueFormatter: (value) => `${value.toFixed(2).replace('.', ',')}` }]}
          
        />
        </Container>
      </Container>

      <Container className="menu">
        {/* <Container className="graficDonuts" style={isSmallScreen ? {height:"44%"} : {height:"100%"}}>
          <PieChart
            slotProps={{legend: {hidden: true}}}
            series={[
              {
                data: graficoRanking,
                innerRadius: 68,
                outerRadius: isSmallScreen ? 50 : 95,
                paddingAngle: 0,
                cornerRadius: 0,
                startAngle: 0,
                endAngle: 360,
                cx: isSmallScreen ? 166 : 122,
                cy: isSmallScreen ? 85 : 122,
              }
            ]
          }
          colors= {[
            Configuracao.COR_CANCEL,
            Configuracao.COR_PRIMARY,
            Configuracao.COR_TERCIARY,
            Configuracao.COR_5,
            Configuracao.COR_4
          ]}
          >
            <PieCenterLabel grafic={1}>Ranking</PieCenterLabel>
          </PieChart>
          <PieChart 
            slotProps={{legend: {hidden: true}}}
            series={[
              {
                data: uData3,
                innerRadius: 68,
                outerRadius: isSmallScreen ? 50 : 95,
                paddingAngle: 0,
                cornerRadius: 0,
                startAngle: 0,
                endAngle: 360,
                cx: isSmallScreen ? 75 : 122,
                cy: isSmallScreen ? 85 : 122,
              }
            ]}
            colors= {[Configuracao.COR_4,Configuracao.COR_5]}
            >
              <PieCenterLabel grafic={2}>Meta %</PieCenterLabel>
          </PieChart>
        </Container>   */}

        <Container className="map">
          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={ handleOpenModalEmissao} open={false}>
            <span className="icon">            
              <NoteAddOutlinedIcon style={{fontSize: 40}}/>
            </span>
            Emitir NFC-e
          </Container>

          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/notas")}>
            <span className="icon">
              <ListAltOutlinedIcon style={{fontSize: 40}}/>
            </span>
            Notas Emitidas
          </Container>
          
          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/produtos")}>
            <span className="icon">
              <CategoryOutlinedIcon style={{fontSize: 40}}/>
            </span>
            Produtos
          </Container>          
          
          <Container className={isSmallScreen ? "options options-mobile" : "options options-default"} onClick={() => handleNavigateTo("/empresaConfig")}>
            <span className="icon">
              <SettingsOutlinedIcon style={{fontSize: 40}}/>                 
            </span>
            Configurações
          </Container>
          
        </Container>
      </Container>
    </Container>
    </>
  );
};

export default Main;