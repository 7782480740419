export const gerarCfo = (csosn, operacao) => {
  /* Operacao
  01-Revenda de mercadoria. (Comércio)"
  02-Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)"
  03-Venda de produção própria (Indústria ou equiparada)"
  04 Venda de produção própria efetuada fora do estabelecimento. (Indústria ou equiparada)*/

  let cfo = "5102"

  if (operacao === "01") {    
    cfo = csosn === "500" ? "5405" : "5102"
  }
  else if (operacao === "02") {    
    cfo = csosn === "500" ? "5405" : "5104"
  }
  else if (operacao === "03") {    
    cfo = "5101"
  }
  else if (operacao === "04") {    
    cfo = "5103"
  }

  return cfo
}