class formatUtils {
  static onlyNumbers(str) {
    const numbers = str.replace(/[^0-9]/g, "");
    return numbers;
  }

  static toFloat(str) {
    const value = parseFloat(str.replace(",", "."));
    return value;
  }
}

export default formatUtils;
