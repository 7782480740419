import React, { useContext, useState } from "react";

import "./style.css"
import Typography from '@mui/material/Typography';
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Container,  IconButton, InputAdornment, Link, TextField, useMediaQuery } from "@mui/material";

/* Imagens e icones*/
import iconFX from '../../assets/fx_sistemas_logo.png';
import logoIMG from "../../assets/notaCerta.png"
import { AccountCircle, LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright "}
      <Link color="inherit" href="https://www.fxsistemas.com.br" target="_blank">
        FX Sistemas
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Login = () => {
  const [user, setUser] = useState({email:"", password: ""})
  const [showPassword, setShowPassword] = useState(false)
  const { handleLogin } = useContext(AuthContext)

  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {    
    e.preventDefault();
    handleLogin(user);
  };

  return (    
    <div className="login-backGround">
      <Container className={isSmallScreen ? "mainContainer" : "mainDesktop2"}>   
        
        <Container className={isSmallScreen ? "logoSmall" : "logo"}>
          <img alt="Nota Certa" id={isSmallScreen ? "logoTipoSmall" : "logoTipoDesktop"} src={logoIMG}/>
        </Container>

        <Container className={isSmallScreen ? "login" : "loginDesktop"}>
          
          <Container>
            <Typography id="tituloLogin">{i18n.t("login.login")}</Typography>
            <Typography id="tituloLogin2">{i18n.t("login.message")}</Typography>
          </Container>     

          <form className="form" noValidate onSubmit={handleSubmit}>
            <center>          
            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{width:"80%"}}
              id="email"
              label={i18n.t("login.form.email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
              inputProps= {{
                startadorment: (
                  <InputAdornment position="start">
                    <AccountCircle/>
                  </InputAdornment>
                )
              }}
            />  
            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{width: "80%"}}
              name="password"
              label={i18n.t("login.form.password")}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"            
              type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />   
              <Button 
                type="submit floatingButton floatingButton:hover"
                style={{width: "80%"}}
                variant="contained"
                color="primary"              
              >
                {i18n.t("login.buttons.submit")}
              </Button>  
            
              <a href="https://www.fxsistemas.com.br" target="_blank" rel="noreferrer">
                <img id="logoFx" src={iconFX} alt="FX Sistemas Logo" />  
              </a>
              { <Copyright /> }
            </center>                    
          </form>
        </Container>      
      </Container>      
    </div>
  );
};

export default Login;