import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { Field, useFormikContext } from "formik";


const FlexSelectField = ({ 
	label, 
	name, 
	width, 
	arrayList, 
	propArray, 
	propArrayId, 
	applyNegativeMargin = false, 
	marginTop = "0px",
	error, 
	helperText, 
	customOnChange}) => {

	const isSmallScreen = useMediaQuery('(max-width:825px)');
	const {handleChange} = useFormikContext();

	return (
		<FormControl 
			style={{
				width: isSmallScreen ? "100%" : width,
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "end",
				marginBottom: isSmallScreen ? "20px" : "0px"	
			}
		}
		>
			<InputLabel
				style={error ? {color: "rgb(211,47,47)"} : {}}>{label}</InputLabel>
			<Field 			
				as={Select}
				name={name}
				label={label}
				error={error}
				style={{ marginBottom: applyNegativeMargin ? "-4px" : "4px",
					       marginTop: marginTop,
				 }}
				onChange={(event) => {
					handleChange(event)

					if (customOnChange) {
						customOnChange(event)
					}									
				}}
			>		

			{arrayList?.map(array => (
				<MenuItem key={array[propArrayId]} value={array[propArrayId]}>{array[propArray]}</MenuItem>
			))}
			</Field>
			{error && <FormHelperText style={{position: "absolute", color: "rgb(211,47,47)", marginBottom: "-16px"}}>{helperText}</FormHelperText>}
		</FormControl>
		
	);
};

export default FlexSelectField;