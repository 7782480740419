import React, { useEffect, useState } from "react";
import { Field } from "formik";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  useMediaQuery
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import { i18n } from "../../../../translate/i18n";

const FlexSelectSearchField = ({ width, label, name, arrayList, propArrayId, propArray, error, helperText}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const [displayedOptions, setDisplayedOptions] = useState(arrayList);

  useEffect(() => {    
    setDisplayedOptions(arrayList)
  }, [arrayList]); 

  //  ***************
  //  ** Functions **
  //  ***************
  const containsText = (text='', searchText) => {
    return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  };

  const handleChangeSearch = (event) => {
    setDisplayedOptions(
      arrayList?.filter(option => containsText(option[propArray], event.target.value))
    );
  }

  const handleResetSearch = () => {
    setDisplayedOptions(arrayList);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <FormControl
      style={{
        width: isSmallScreen ? '100%' : width,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end',
      }}
    >
      <InputLabel style={error ? {color: "rgb(211,47,47)"} : {}}>{label}</InputLabel>

      <Field
        as={Select}
        name={name}
        label={label}
        style={{ marginBottom: '4px' }}
        MenuProps={{ disableAutoFocusItem: true, autoFocus: false }}
        onClose={handleResetSearch}
        error={error}						
      >
        <ListSubheader>
          <TextField
            autoFocus={true}
            fullWidth
            size='small'
            placeholder={i18n.t('search.search')}
            style={{ marginTop: '1em' }}
            onChange={event => handleChangeSearch(event)}
            onKeyDown={event => { if (event.key !== 'Escape') { event.stopPropagation(); } }}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </ListSubheader>

        {displayedOptions?.map(array => (
          <MenuItem
            onKeyDown={(event) => event.stopPropagation()}
            key={array[propArrayId]}
            value={array[propArrayId]}
          >
            {array[propArray]}
          </MenuItem>
        ))}
      </Field>
      {error && <FormHelperText style={{position: "absolute", color: "rgb(211,47,47)", marginBottom: "-16px"}}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FlexSelectSearchField;