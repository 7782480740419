import { Configuracao } from "../../../configuracao";
import api from "../../../services/api";
import { buscarNaturezaOperacao } from "./funcoes/buscarNaturezaOperacao";

export const gerarIde = async (operacao, identificador, empresaId, cUF, cMunFG) => {
  try {    	
    const configuracaoResponse = await api.get(`v1/empresas-configuracoes-payload/${empresaId}`);
    const configuracaoNfce = configuracaoResponse.data.configuracaoNfce;        

    const naturezaOperacao = buscarNaturezaOperacao(configuracaoResponse.data.tributacao.operacao);

    const ide = {
      id: operacao === "ALTERAR" ? identificador.id : undefined,
      cUF: cUF, 
      natOp: naturezaOperacao,
      finNFe: identificador.finNFe,
      indFinal: identificador.indFinal,
      mod: identificador.mod,
      serie: configuracaoNfce.serie || "1",
      nNF: null,
      dhEmi: null,
      tpNF: identificador.tpNF,
      idDest: identificador.idDest,      
      cMunFG: cMunFG,
      tpImp: identificador.tpImp,
      tpEmis: identificador.tpEmis,
      cDV: null,      
      tpAmb: configuracaoNfce.ambiente === "Produção" ? "1" : "2",
      indPres: identificador.indPres,
      procEmis: "0",
      verProc: Configuracao.VERSAO_SISTEMA,
      operacao: configuracaoResponse.data.tributacao.operacao
    };
    
    return ide;
  } catch (error) {        
    throw error; 
  }
};