import React, { useContext, useState } from "react";
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CategoryOutlined,
  ExpandLess,
  ExpandMore,
  LogoutOutlined,
  NoteAltOutlined,
  PersonOutlineOutlined,
  SettingsOutlined,
  StoreOutlined,
} from "@mui/icons-material";

import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { AuthContext } from "../context/Auth/AuthContext";
import { i18n } from "../translate/i18n";
import { useNavigate } from "react-router-dom";

const MainListItems = ({ closeDrawer, isSmallScreen }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { handleLogout } = useContext(AuthContext);
  const navigate = useNavigate(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openRegistrationsMenu, setOpenRegistrationsMenu] = useState(false);

  //  ***************
  //  ** Functions **
  //  ***************
  const handleClickRegistrationsMenu = () => { setOpenRegistrationsMenu((previousValue) => !previousValue); };

  const handleClickLogout = () => { handleLogout(); };

  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    navigate(route);
    isSmallScreen && closeDrawer();
  };

  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      {/*
       ***---- Registrations ----***
       */}
      <div onClick={handleClickRegistrationsMenu}>
        <ListItemButton
          button="true"
          selected={
            selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2
          }
        >
          <ListItemIcon>
            <NoteAltOutlined />
          </ListItemIcon>
          <ListItemText
            primary={i18n.t("drawerMenu.items.mainItems.registrations")}
          />

          <IconButton>
            {openRegistrationsMenu ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemButton>
      </div>

      {/*
       ***---- Registrations Collapse ----***
       */}
      <Collapse
        component="li"
        in={openRegistrationsMenu}
        timeout="auto"
        unmounonexit="true"
      >
        <List disablePadding>
          {/*
           ***---- Companies ----***
           */}
          <ListItemButton
            button="true"
            onClick={() => handleListItemClick(0, "empresas")}
            style={{ paddingLeft: "25px" }}
          >
            <ListItemIcon>
              <StoreOutlined />
            </ListItemIcon>
            <ListItemText
              primary={i18n.t("drawerMenu.items.subItems.companies")}
            />
          </ListItemButton>

          {/*
           ***---- Users ----***
           */}
          <ListItemButton
            button="true"
            selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1, "users")}
            style={{ paddingLeft: "25px" }}
          >
            <ListItemIcon>
              <PersonOutlineOutlined />
            </ListItemIcon>
            <ListItemText primary={i18n.t("drawerMenu.items.subItems.users")} />
          </ListItemButton>

          {/*
           ***---- Products ----***
           */}
          <ListItemButton
            button="true"
            selected={selectedIndex === 2}
            onClick={() => handleListItemClick(2, "produtos")}
            style={{ paddingLeft: "25px" }}
          >
            <ListItemIcon>
              <CategoryOutlined />
            </ListItemIcon>
            <ListItemText
              primary={i18n.t("drawerMenu.items.subItems.products")}
            />
          </ListItemButton>
        </List>
      </Collapse>

      {/*
       ***---- Notas ----***
       */}
       <ListItemButton
        button="true"
        selected={selectedIndex === 3}
        onClick={() => {
          if (window.location.href.includes("/")) {
            window.location.href = "/?emitirNfce=1"   
          } else  {
            handleListItemClick(3, "?emitirNfce=1")
          }
        }}
      >
        <ListItemIcon>
          <NoteAddOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("drawerMenu.items.mainItems.emission")} />
      </ListItemButton>

      <ListItemButton
        button="true"
        selected={selectedIndex === 4}
        onClick={() => handleListItemClick(4, "Notas")}
      >
        <ListItemIcon>
          <ListAltOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("drawerMenu.items.mainItems.items")} />
      </ListItemButton>

      {/*
       ***---- Settings ----***
       */}
      <ListItemButton
        button="true"
        selected={selectedIndex === 5}
        onClick={() => handleListItemClick(5, "empresaConfig")}
      >
        <ListItemIcon>
          <SettingsOutlined />
        </ListItemIcon>
        <ListItemText primary={i18n.t("drawerMenu.items.mainItems.settings")} />
      </ListItemButton>

      {/*
       ***---- Logout ----***
       */}
      <ListItemButton button="true" onClick={handleClickLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary={i18n.t("drawerMenu.items.mainItems.logout")} />
      </ListItemButton>
    </div>
  );
};

export default MainListItems;
