import React, { useState, useContext, useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Button,
  Drawer,
  Divider,
  List,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import clsx from "clsx";

import { AuthContext } from "../context/Auth/AuthContext";
import { i18n } from "../translate/i18n";
import BackdropLoading from "../components/BackdropLoading";
import logoIcon from '../assets/icon-dark.png';
import MainListItems from "./MainListItems";

import BrazilFlag from '../assets/flags/br.svg';
import SpainFlag from '../assets/flags/es.svg';
import USFlag from '../assets/flags/us.svg';

import "./loggedInLayoutStyle.css";
import api from "../services/api";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  layoutRoot: { display: "flex", height: "100vh", },

  drawerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1em",
    cursor: "pointer",
  },

  applicationLogo: { 
    borderRadius: "20px", 
    margin: theme.spacing(2, 1, 2.5, 2), 
    width: "32px", 
    height: "auto" 
  },

  toggleSubContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },
  
  drawerPaper: {
    ...theme.scrollbarStyles,
    padding: "0 0 0 0",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaperClose: {
    overflowX: "hidden",
    ...theme.scrollbarStyles,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: { width: theme.spacing(9), },
  },

  paper: {
    borderRadius:"20px",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  miscButtons: {
    margin: theme.spacing(0, 0, 0, 0),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
  },

  miscButtonsClose: { margin: theme.spacing(0, 0, 0, 0), },

  buttonFirst: {
    borderRadius:"10px",
    margin: theme.spacing(1, 1, 0, 1.5),
    alignItems: "left",
    justifyContent: "flex-left",
  },

  buttonSecond: { borderRadius:"10px", margin: theme.spacing(1, 0, 0, 0), },
  buttonClose: { borderRadius: "10px", margin: theme.spacing(1, 0, 0, 0.5), },
  flag: { borderRadius: "50%", height: "15px", marginRight: "5px", },
}));

const LoggedInLayout = ({ drawerOpen, setDrawerOpen, handleToggleMenu }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const navigate = useNavigate();
  const { loading } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery('(max-width:825px)');

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [isWizard, setWizard] = useState(true);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => { if (document.body.offsetWidth > 600) { setDrawerOpen(true); } }, [setDrawerOpen]);

  useEffect(() => {
    if (isSmallScreen) { setDrawerVariant("temporary"); }
    else { setDrawerVariant("permanent"); }
  }, [drawerOpen, isSmallScreen]);

  useEffect(() => {
    (async () => {
      try {
        const { data: dataEmpresa } = await api.get("/v1/empresas");
        if (dataEmpresa.empresas.length === 0) {
          setWizard(false);
        }
      } catch (error) {
      }
    })();
  }, []);


  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const handleChangingLanguage = (i18nCode) => {
    handleCloseLanguageMenu();
    localStorage.setItem("i18nextLng", i18nCode);
    i18n.changeLanguage(i18nCode);
  };
  
  const handleClick = () => {
    if (!drawerOpen) {
      handleToggleMenu()
    } 
    else {
      navigate("/");
    }
  };



  //  ************
  //  ** Return **
  //  ************
  if (loading) { return <BackdropLoading />; }
  
  return (
    <div className={classes.layoutRoot}>
      {/* 
        **********
        ** Menu **
        **********
      */}
      {isWizard && (<Drawer
        variant= {drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)}}
        open={drawerOpen}
        anchor={"left"}
        onClose={() => setDrawerOpen(false)}
      >     

        {/*
          ************
          ** Header **
          ************
        */}
        <div className={classes.drawerHeader}>          
          <img className={classes.applicationLogo}             
            src={logoIcon} alt="" 
            onClick={handleClick}
          />
          {drawerOpen && (
            <div className={classes.toggleSubContainer}>
              <span onClick={handleClick}>
                <b>{i18n.t("drawerMenu.title")}</b>
              </span>
              <IconButton onClick={handleToggleMenu} >
                <ChevronLeftOutlinedIcon className={classes.toggleIcon} />
              </IconButton>
            </div>
          )}
        </div>

        <Divider />

        {/* 
          ***********
          ** Items **
          ***********
        */}
        <List>
          <MainListItems closeDrawer={() => setDrawerOpen(false)} isSmallScreen={isSmallScreen} />
        </List>

        <Divider />

        {/*
          *******************
          ** Miscellaneous **
          *******************
        */}
        {/*<div className={drawerOpen ? classes.miscButtons : classes.miscButtonsClose}>
          <Button
            className={drawerOpen ? classes.buttonFirst : classes.buttonClose}
            aria-label="languages"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenLanguageMenu}
            color="inherit"
            startIcon={<TranslateOutlinedIcon />}
          />

          <Menu
            id="menu-language"
            anchorEl={anchorEl}
            getcontentanchorel={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={languageMenuOpen}
            onClose={handleCloseLanguageMenu}
          >
            <MenuItem onClick={() => { handleChangingLanguage("pt-BR") }}>
              <img className={classes.flag} src={BrazilFlag} alt="Brasil - " />
              {i18n.t("languages.language1")}
            </MenuItem>

            <MenuItem onClick={() => { handleChangingLanguage("en-US") }}>
              <img className={classes.flag} src={USFlag} alt="US - " />
              {i18n.t("languages.language2")}
            </MenuItem>
      </Menu>

          {drawerOpen ? <></> : <br/>}

          <Button
            className={drawerOpen ? classes.buttonSecond : classes.buttonClose}
            aria-label="userProfile"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            // onClick={handleOpenUserModal}
            color="inherit"
            startIcon={<AccountCircleIcon />}
          />
        </div>*/}
      </Drawer>)}
      
      {/* 
        *******************
        ** Child Content **
        *******************
      */}
      <Outlet />
    </div>
  );
};

export default LoggedInLayout;