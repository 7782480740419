import React, { useState, useEffect } from "react";
import { Button, Dialog, Divider, useMediaQuery, IconButton} from "@mui/material";

import "./style.css";
import { toast } from "react-toastify";
import { i18n } from "../../../translate/i18n";

import api from "../../../services/api";
import { Container } from "@mui/system";
import MainHeaderFX from "../../MainHeaderFx";
import FlexMessage from "../../message/FlexMessage";
import { Configuracao } from "../../../configuracao";
import InformarQtdPreco from "../../InformarQtdPreco";
import InformarCpf from "../../InformarCpf";
import { geraJsonNota } from "../../../utils/funcoes/geraJsonNota/geraJsonNota";
import { gerarDescontoItens } from "../../../utils/funcoes/calculos/gerarDescontoItem";

import PixIcon from '@mui/icons-material/Pix';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import toastError from "../../../errors/toastError";

const EmissaoPagamentoModal = ({ open, onClose, subTotal, json }) => {  
  // indPag = 0-VISTA 1-PRAZO
  // 01=Dinheiro           02=Cheque          03=Cartão de Crédito      04=Cartão de Débito    05=Crédito Loja-PRAZO
  // 10=Vale Alimentação   11=Vale Refeição   12=Vale Presente          13=Vale Combustível    15=Boleto Bancário-PRAZO
  // 16=Depósito Bancário  17=Pagamento(PIX)  18=Transferência bancária 19=Programa de fidelidade, Cashback, Crédito Virtual
  // 90= Sem pagamento     99=Outros
  
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [loading, setLoading] = useState(false);  
  const [pagamento, setPagamento] = useState([]);
  const [valorTotal, setValorTotal] = useState(0);
  const [operacao, setOperacao] = useState("PRECO");
  const [errorNota, setErrorNota] = useState(false);  
  const [totalPago, setTotalPagamento] = useState(0);
  const [informarCpf, setInformarCpf] = useState(false);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [isCpfEntered, setIsCpfEntered] = useState(false);
  const [totalFaltaPagar, setTotalFaltaPagar] = useState(0);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState("");
  const [informarQtdPreco, setInformarQtdPreco] = useState(false);
  const [descricaoOpcaoSelecionada, setDescricaoOpcaoSelecionada] = useState("");
  const [descricaoFaltaPagarTroco, setDescricaoFaltaPagarTroco] = useState("Total Restante");  
  const [confInformarCpf, setConfInformarCpf] = useState(false);
  const [cnpjEmpresa, setCnpjEmpresa] = useState("")

  const isSmallScreen = useMediaQuery('(max-width:825px)'); 
  
  useEffect(() => {
    (async () => {
      try {
        const dataEmpresa  = await api.get("/v1/empresas");
        const responseData = dataEmpresa.data
        if (responseData.count > 0) {
          setCnpjEmpresa(dataEmpresa.data.empresas[0].cnpj)
          const empresaId = dataEmpresa.data.empresas[0].id
          const confResponse  = await api.get(`/v1/empresas-configuracoes-payload/${empresaId}`);
          const confdata = confResponse.data.configuracaoNfce
          
          if (confdata) {
            setConfInformarCpf(confdata.informarCPF)
          }
        }
        
      } catch (error) {
        toastError(error);
      }
    })();    
  }, []); 
  
  useEffect(() => {
    const total = parseFloat(subTotal) - parseFloat(valorDesconto);
    setValorTotal(parseFloat(total.toFixed(2), 10));
  }, [subTotal, valorDesconto]);

  useEffect(() => {
    const restante = parseFloat(valorTotal) - parseFloat(totalPago);
    setTotalFaltaPagar(parseFloat(restante.toFixed(2), 10));
  }, [valorTotal, totalPago]);

  useEffect(() => {
    if (totalFaltaPagar < 0) {
      setDescricaoFaltaPagarTroco("Troco")
      setTotalFaltaPagar(Math.abs(totalFaltaPagar))
    }    
  }, [totalFaltaPagar]);  

  useEffect(() => {
    if (isCpfEntered && !informarCpf) {      
      handleConfirm()      
    }
  }, [isCpfEntered]) 

  const handleCloseModal = (limparModal, notaRetorno) => {
    onClose(limparModal, notaRetorno);
    setPagamento([]); 
    setTotalPagamento(0)  
    setValorDesconto(0)
    setDescricaoFaltaPagarTroco("Total Restante") 
    setIsCpfEntered(false)
  };

  const handleInformarQtdPreco = (tipo, descricao) => {
    setOpcaoSelecionada(tipo)
    setDescricaoOpcaoSelecionada(descricao)
    setInformarQtdPreco(true);
    setOperacao("PRECO");
  }

  const handleCloseModalInformarQtdPreco = (qtdPreco) => {    
    setInformarQtdPreco(false);
    setOperacao("PRECO");    
  }

  const handleCloseModalInformarCpf = () => {    
    setInformarCpf(false);      
    setIsCpfEntered(true)
  }

  const handleQtdPrecoSelecionado = (qtdPreco) => {

    if (operacao === "DESCONTO") {
      setValorDesconto(qtdPreco);
    } else {
      if (opcaoSelecionada && qtdPreco > 0) {
        const tipoPagamentoExistenteIndex = pagamento.findIndex(item => item.tPag === opcaoSelecionada);
    
        if (tipoPagamentoExistenteIndex !== -1) {
          const pagamentoAtualizado = [...pagamento];
          pagamentoAtualizado[tipoPagamentoExistenteIndex].vPag += qtdPreco;
          setPagamento(pagamentoAtualizado);  
          
          //setTotalPagamento(prevTotal => prevTotal + qtdPreco);
          
          setTotalPagamento(prevTotal => {
            const novoTotal = prevTotal + qtdPreco;
            return parseFloat(novoTotal.toFixed(2));
          });
        } else {        
          const novoPagamento = {
            indPag: "0",
            tPag: opcaoSelecionada,
            descricao: descricaoOpcaoSelecionada,
            vPag: qtdPreco,
          };
          setPagamento(prevPagamento => [...prevPagamento, novoPagamento]);
          setTotalPagamento(prevTotal => {
            const novoTotal = prevTotal + qtdPreco;
            return parseFloat(novoTotal.toFixed(2));
          });          
        }
      } else {
        toast.error("Selecione uma opção de pagamento e insira um valor válido.");
      }
    }  
    
    setOpcaoSelecionada("");
    setDescricaoOpcaoSelecionada("");
  };  

  const handleDeletePagamento = (index, valor) => {
    const updatedPagamento = [...pagamento]; 
    updatedPagamento.splice(index, 1); 
    setPagamento(updatedPagamento); 
    
    const novoTotalPago = totalPago - valor;
    const novoTotalFaltaPagar = valorTotal - novoTotalPago;
    setTotalPagamento(novoTotalPago);
    setTotalFaltaPagar(novoTotalFaltaPagar);

    if (novoTotalFaltaPagar >= 0) {
      setDescricaoFaltaPagarTroco("Total Restante")
    }
  };   

  const handleGerarDocumento = () => {
    if (confInformarCpf) {
      setInformarCpf(true)
    } 
    else {
      handleConfirm()
    }
  };

  const handleConfirm = async () => {
    setLoading(true);  
    let infTotais
    
    if (valorDesconto > 0) {
      const itens = gerarDescontoItens(json.item, valorDesconto, "valorDesconto");

      const newJson = {
        ...json, 
        ...itens                
      };

      infTotais = {...newJson, icmsTot: { 
        ...json.icmsTot, 
        valorProduto: subTotal,
        valorDesconto: valorDesconto,
        totalNf: subTotal - valorDesconto
      }}      
    } 
    else  {      
      infTotais = {...json, icmsTot: { 
        ...json.icmsTot, 
        valorProduto: subTotal,
        valorDesconto: valorDesconto,
        totalNf: subTotal - valorDesconto,
      }}  
    }   

    const data = { ...infTotais , dest: { cpfCnpj: cpfCnpj}, pagamento: [ ...pagamento ]}  

    try {
      const nota = await geraJsonNota(data, 'ADICIONAR')

      if (nota.status === 200) {        
        const envio = await api.post("/v1/notas", nota.nota);
        
        if (envio.data.cStat === '100') {
          toast.success(`NFC-e enviada ${envio.data.chave}`);
        } else {                 
          setErrorNota(envio.data.mensagem);
          handleCloseModal(true);
        }
  
        handleCloseModal(true, envio.data);
      } 
      else {
        setErrorNota(nota.message || 'Ocorreu um erro ao enviar a NFC-e');
        handleCloseModal(true);
      }
    } catch (error) { 
      const errorMsg = error.response?.data?.message || error.response.data.error;       
      setErrorNota(errorMsg); 
      handleCloseModal(true);
    }
    setLoading(false);     
  }

  const handleHabilitaNfce = () => {    
    return (subTotal - valorDesconto) <= totalPago && !loading;
  }

  return (
    <>
      <InformarQtdPreco
        open={informarQtdPreco}
        title={descricaoOpcaoSelecionada}
        faltaPagar={totalFaltaPagar}
        valorDesconto={valorDesconto}
        onClose={handleCloseModalInformarQtdPreco}
        onQtdPrecoSelecionado={handleQtdPrecoSelecionado}        
        operacao={operacao}
      /> 

      <InformarCpf
        open={informarCpf}
        cnpjEmpresa={cnpjEmpresa}
        onClose={handleCloseModalInformarCpf}
        onCpfCnpjSelecionado={setCpfCnpj}
      />     

      <FlexMessage
        open={errorNota}
        onClose={() => setErrorNota(false)}
        title={"Erro ao enviar a NFCe!"}
        tipo={"error"}
        message={errorNota}
      />

      <Dialog
        open={open}         
        fullWidth={true}
        fullScreen={isSmallScreen} 
        maxHeight={true}        
        PaperProps={!isSmallScreen ? {
          style: {
            height: "100%",            
            maxHeight: "76vh", 
            minHeight: "580px",
            maxWidth: "70vh",
            minWidth: "500px"
          },
        }: undefined}
        
      >
        <MainHeaderFX 
          title={i18n.t("FORMA DE PAGAMENTO")}
          leftContainerType={"modal"}
          rightContainerType={"hidden"}   
          backModal={true}
          handleCloseModal={() => handleCloseModal(false)}           
        />

        <Container class="containerMain">     
          {/* Parte de cima */}
          <Container class="pagamento">
            <Container class="pagamentoInformacao">
              
              <Container class="containerSubTotal" >
                <div style={{display: "flex", 
                    justifyContent: "flex-end"}}
                    onClick={() => {
                      if (pagamento.length === 0) {
                        setOperacao("DESCONTO");                              
                        setDescricaoOpcaoSelecionada("Informe o desconto");
                        setInformarQtdPreco(true);
                      }
                    }}
                    title={pagamento.length > 0 ? "Desconto indisponível devido a pagamento pendente" : "Clique para aplicar desconto"}
                  >
                      <LocalOfferOutlinedIcon style={{ color: pagamento.length > 0 ? Configuracao.COR_5 :  Configuracao.COR_PRIMARY}}/>
                      &nbsp;
                      <span style={{ color: pagamento.length > 0 ? 'gray' :  Configuracao.COR_PRIMARY}}>
                        Aplicar desconto
                      </span>
                    </div>
                
                <Divider/>
                <Container class="pagamentosTitle"
                  style={{height: "25%"}}
                >
                  <p>SubTotal</p>
                  <p>{`R$ ${parseFloat(subTotal).toFixed(2) || "0.00"}`}</p>
                </Container>
                
                <Container class="pagamentosTitle" >
                  <div>
                    <p>Desconto</p>                      
                  </div>
                  <p>{`- R$ ${parseFloat(valorDesconto).toFixed(2) || "0.00"}`}</p>
                </Container>

                <Container class="pagamentosTitle" >
                  <p>Total</p>
                  <p class="negritoPreco">{`R$ ${parseFloat(valorTotal).toFixed(2) || "0.00"}`}</p>
                </Container>
              </Container>
              
              
              <Divider/>

              <Container class="containerPagamento" 
                style={{height: "calc(90% - 110px)"}}
              >                  
                {pagamento.map((pagamento, index) => (
                  <Container class="containerItensPagamento" key={index}>
                    <p>{pagamento.descricao} </p>
                    <Container class="containerItensValorDelete">  
                      <p>{parseFloat(pagamento.vPag).toFixed(2)} </p>
                      <IconButton
                        tabIndex={"-1"}
                      >
                        <DeleteOutlineOutlinedIcon
                          onClick={() => handleDeletePagamento(index, pagamento.vPag)}
                        />
                      </IconButton>
                    </Container>
                  </Container>
                ))}
              </Container>

              <Divider/>
              
              <Container >
                <Container class="pagamentosTitle">
                  <p>Total Pago</p>
                  <p class="negritoPreco">{`R$ ${parseFloat(totalPago).toFixed(2) || "0.00"}`}</p>
                </Container>

                <Container class="pagamentosTitle" 
                  style={{color: descricaoFaltaPagarTroco === "Troco" ? "#D21312" : ""}}                    
                >
                  <p>{descricaoFaltaPagarTroco}</p>
                  <p>{`R$ ${parseFloat(totalFaltaPagar).toFixed(2) || "0.00"}`}</p>
                </Container>                
                
              </Container>
            </Container>
          </Container>

          {/* Parte de baixo */}
          <Container class="opcoes">
            <Container class="pagamentoOpcoes">                  
              {/* 01=Dinheiro 02=Cheque 03=Cartão de Crédito 04=Cartão de Débito 05=Crédito Loja 10=Vale Alimentação 
                  11=Vale Refeição 12=Vale Presente 13=Vale Combustível 15=Boleto Bancário 16=Depósito Bancário 
                  17=Pagamento Instantâneo (PIX) 18=Transferência bancária, Carteira Digital
                  19=Programa de fidelidade, Cashback, Crédito Virtual   90= Sem pag */}
              <Button  
                tabIndex="-1" 
                color="primary"
                variant="text"                          
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}    
                style={{width: "25%", height: "45%", textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("01", "Dinheiro")}
              >
                <div class="divButtonPagamento" 
                      style={{display: "flex", 
                              flexDirection: "column",                                                 
                              width: "100%",
                              height: "100%",
                              justifyContent: "center", 
                              alignItems: "center"}}
                >
                  <LocalAtmOutlinedIcon/>
                  Dinheiro
                </div>
              </Button>

              <Button   
                tabIndex="-1"
                color="primary"
                variant="text"      
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}    
                style={{width: "25%", height: "45%", textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("03", "Cartão de crédito")}
              >
                <div class="divButtonPagamento" 
                      style={{display: "flex", 
                              flexDirection: "column",                                                 
                              width: "100%",
                              height: "100%",
                              justifyContent: "center", 
                              alignItems: "center"}}
                >
                  <CreditCardOutlinedIcon/>                    
                  Crédito
                </div>
              </Button>

              <Button
                tabIndex="-1"
                color="primary"
                variant="text"      
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}    
                style={{width: "25%", height: "45%", textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("04", "Cartão de débito")}
              >
                <div class="divButtonPagamento" 
                      style={{display: "flex", 
                              flexDirection: "column",                                                 
                              width: "100%",
                              height: "100%",
                              justifyContent: "center", 
                              alignItems: "center"}}
                >
                  <CreditCardOutlinedIcon/>
                  Débito
                </div>
              </Button>

              <Button  
                tabIndex="-1" 
                color="primary"
                variant="text"      
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}   
                style={{width: "25%", height: "45%", textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("05", "Prazo")}
              >
                <div class="divButtonPagamento" 
                      style={{display: "flex", 
                              flexDirection: "column",                                                 
                              width: "100%",
                              height: "100%",
                              justifyContent: "center", 
                              alignItems: "center"}}
                >
                  <FeaturedPlayListOutlinedIcon/>
                  Prazo
                </div>
              </Button>

              <Button  
                tabIndex="-1"
                color="primary"
                variant="text"      
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}
                style={{width: "25%", height: "45%", textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("17", "Pix")}
              >
                <div class="divButtonPagamento" 
                      style={{display: "flex", 
                              flexDirection: "column",                                                 
                              width: "100%",
                              height: "100%",
                              justifyContent: "center", 
                              alignItems: "center"}}
                >
                  <PixIcon/>
                  Pix
                </div>
                
              </Button>

              {/* <Button   
                tabIndex="-1"
                color="primary"
                variant="text"                          
                disabled={totalFaltaPagar <= 0 || descricaoFaltaPagarTroco === "Troco"}
                style={{width: "30%", height: 70, textTransform: "none" }}
                onClick={() => handleInformarQtdPreco("99", "Outros")}
              >
                <div class="divButtonPagamento" style={{display: "flex", 
                                            flexDirection: "column",                                                 
                                            width: "100%",
                                            height: "100%",
                                            justifyContent: "center",                                                
                                            alignItems: "center"}}
                >
                    <AddBoxOutlinedIcon/>
                    Outros
                  </div>
              </Button>                   */}
            </Container>

            <div class="opcaoButton" >
              <Button
                tabIndex="-1"
                color="primary"
                variant="contained"
                onClick={() => {handleGerarDocumento()}}
                disabled={!handleHabilitaNfce(subTotal, valorDesconto, totalPago, loading)}                  
                style={{width: "95%", height: 50, borderRadius: 10}}
              >
                GERAR NFC-e
              </Button>              
            </div>
          </Container>
        </Container>                 
      </Dialog>
    </>
    
  );
};

export default EmissaoPagamentoModal;

