import React, { useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider, AuthContext } from "../context/Auth/AuthContext";
import Embalagem from "../pages/Embalagem";
import Empresa from "../pages/Empresa";
import Generico from "../pages/Generico";
import LoggedInLayout from "../layout/index";
import Login from "../pages/Login/";
import Main from "../pages/Main";
import Wizard from "../pages/Wizard";
import Page404 from "../pages/Page404/";
import Produto from "../pages/Produto/";
import Profile from "../pages/Profile/";
import Users from "../pages/Users";
import EmpresaConfig from "../pages/EmpresaConfig";
import Nota from "../pages/Vendas";

const Rotas = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const handleToggleMenu = () => {
    setDrawerOpen((previousValue) => !previousValue);
  };

  const PrivateRoute = ({ page }) => {
    const { isAuth } = useContext(AuthContext);
    return !isAuth ? <Navigate to="/login" /> : page ;
  };

  const LoginRoute = ({ page }) => {
    const { isAuth } = useContext(AuthContext);
    return isAuth ? <Navigate to="/" /> : page ;
  };


  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route index path="/login" element={<LoginRoute page={<Login />}/>}/>

          <Route
            element={
              <LoggedInLayout
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                handleToggleMenu={handleToggleMenu}
              />
            }
          >
            <Route path="/" element={<PrivateRoute page={<Main handleToggleMenu={handleToggleMenu}/>}/> }/>            
            <Route path="/users" element={<PrivateRoute page={<Users />}/>} />
            <Route path="/MyProfile" element={<PrivateRoute page={<Profile />}/>} />
            <Route path="/embalagens" element={<PrivateRoute page={<Embalagem />}/>} />
            <Route path="/empresas" element={<PrivateRoute page={<Empresa />}/>} />
            <Route path="/empresaConfig" element={<PrivateRoute page={<EmpresaConfig />}/>} />
            <Route path="/produtos" element={<PrivateRoute page={<Produto />}/>} />
            <Route path="/notas" element={<PrivateRoute page={<Nota/>}/>} />
            <Route path="/wizard" element={<PrivateRoute page={<Wizard />}/> } />
          </Route>
          <Route path="*" element={<Page404 />}/>
        </Routes>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};
export default Rotas;
