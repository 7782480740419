import React, { useState, useEffect } from "react";
import Webcam from 'webcam-easy';
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import {
  Alert,
  Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles } from "@mui/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	videoContainer: {
    width: "95%",
    height: "80%",
  },

  canvasContainer: {

  },

  displayedElement: {
    display: "block",
  },

  notDisplayedElement: {
    display: "none",
  },
  
  iconButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }
}));

const CameraModal = ({ open, onClose, ticketId }) => {
  // ***---- Datas ----***
	const classes = useStyles();
  const [webcamList, setWebcamList] = useState([]);
  const [webcam, setWebcam] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isCameraSnapped, setIsCameraSnapped] = useState(false);
  const [snapedPhoto, setSnapedPhoto] = useState(null);

  // ***---- Use Effects ----***
  useEffect(() => {
    if (!open) return;

    const getVideoInputs = (mediaDevicesArray) => {
      const videoDevicesArray = mediaDevicesArray.filter(mediaDevice => mediaDevice.kind === "videoinput");
      setWebcamList(videoDevicesArray);
    };

    navigator.mediaDevices.enumerateDevices()
      .then(getVideoInputs)
      .catch(exception => {
        toast.info(i18n.t("cameraModal.toasts.exceptionListingDevices"));
        console.log("Exception caught when trying to list video devices:", exception);
      });
  }, [open]);
  

  // ***---- Functions ----***
	const handleClose = () => {
    webcam && webcam.stop();
    setIsCameraOn(false);
    setIsCameraSnapped(false);
    setSnapedPhoto(null);
		onClose();
	};

  const handleTurnOnCamera = () => {
    const webcamElement = document.getElementById("video");
    const canvasElement = document.getElementById("canvas");
    const webcamAPI = new Webcam(webcamElement, 'user', canvasElement);

    webcamAPI.stream()
      .then(() => { setWebcam(webcamAPI); })
      .catch(err => { console.log("WebCam Error:", err); });

    webcamAPI.flip();

    setIsCameraSnapped(false);
    setIsCameraOn(true);
  };

  const handleTurnOffCamera = () => { 
    webcam.stop();
    setIsCameraOn(false);
    setIsCameraSnapped(false);
  };

  const base64ImageToFile = (base64Image, filename) => {
    const base64ImageArray = base64Image.split(',');
  
    const mimetype = base64ImageArray[0].match(/:(.*?);/)[1];
  
    const decodedBase64Image = atob(base64ImageArray[1]);
    let decodedBase64ImageLength = decodedBase64Image.length;
  
    const imageArray = new Uint8Array(decodedBase64ImageLength);
    
    while (decodedBase64ImageLength--) {
      imageArray[decodedBase64ImageLength] = decodedBase64Image.charCodeAt(decodedBase64ImageLength);
    }
    
    return new File([imageArray], filename, { type: mimetype });
  }

  const handleSnapCamera = () => {
    const base64Image = webcam.snap();
    const imageFile = base64ImageToFile(base64Image, "snap.png")

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(imageFile);
    setSnapedPhoto(dataTransfer);

    handleTurnOffCamera();
    setIsCameraSnapped(true);
  }

  const confirmSnap = () => {
    const fileInput = document.getElementById("produtoFotoInput");
    fileInput.files = snapedPhoto.files;
    const inputFotoImage = document.getElementById("inputFotoImage");
    inputFotoImage.src = URL.createObjectURL(fileInput.files[0]);

    handleClose();
  }

  function handleDataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  const handleSavePhoto = async () => {
    try {
      const canvasElement = document.getElementById("canvas");
      const photoUrl = canvasElement.toDataURL();
      const photoFile = handleDataURIToBlob(photoUrl);
      const formData = new FormData();
      formData.append("body", "");
      formData.append("fromMe", true);
      formData.append("medias", photoFile, "image.png");
      await api.post(`/messages/${ticketId}`, formData);
    } catch (error) {
      console.log("Handle Save Photo Error:", error);
      toastError(error);
    }
    
    handleClose();
  };


  // ***---- Return ----***
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Câmera
				</DialogTitle>

        <DialogContent dividers>
          {webcamList.length === 0 && (
            <Alert severity="info">{i18n.t("cameraModal.messages.noVideoInputs")}</Alert>
          )}

          {webcamList.length > 0 && (
            <>
            {/*
              ***********
              ** Video **
              ***********
            */}
            <div>
              <video 
                id="video"
                className={isCameraSnapped 
                  ? `${classes.videoContainer} ${classes.notDisplayedElement}` 
                  : `${classes.videoContainer} ${classes.displayedElement}`
                }
                autoPlay
                playsInline
              >

              </video>
              <canvas 
                id="canvas" 
                className={isCameraSnapped 
                  ? `${classes.videoContainer} ${classes.displayedElement}` 
                  : `${classes.videoContainer} ${classes.notDisplayedElement}`
                }
              >

              </canvas>
            </div>

            {/* 
              *************
              ** Buttons **
              *************
            */}
            <div className={classes.iconButtonsContainer}>

              {/* Turn on camera */}
              <Tooltip title={i18n.t("cameraModal.tooltips.turnOnCamera")} placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={handleTurnOnCamera}
                  disabled={isCameraOn ? true : false}
                >
                  <VideocamIcon />
                </IconButton>
              </Tooltip>

              {/* Turn off camera */}
              <Tooltip title={i18n.t("cameraModal.tooltips.turnOffCamera")} placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={handleTurnOffCamera}
                  disabled={isCameraOn ? false : true}
                >
                  <VideocamOffIcon />
                </IconButton>
              </Tooltip>

              {/* Take photo */}
              <Tooltip title={i18n.t("cameraModal.tooltips.snapCamera")} placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={handleSnapCamera}
                  disabled={isCameraOn ? false : true}
                >
                  <AddAPhotoIcon />
                </IconButton>
              </Tooltip>

              {/* Save photo */}
              <Tooltip title={i18n.t("cameraModal.tooltips.sendPhoto")} placement="top-start" arrow>
                <IconButton
                  color="inherit"
                  className={classes.floatingButton}
                  onClick={confirmSnap}
                  disabled={isCameraSnapped ? false : true}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            Cancelar
          </Button>
        </DialogActions>
			</Dialog>
		</div>
	);
};

export default CameraModal;
