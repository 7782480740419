export const gerarDetPag =  (operacao, arrayPagamento) => {
  let detPag = []
		Promise.all (arrayPagamento.map(async (pagamento) => { 
			let indicador = "0";
    
			if (pagamento.tPag === "05" || pagamento.tPag === "15" || pagamento.tPag === "99") {
				indicador = "1";
			}

			detPag.push({
				id: operacao === "ALTERAR" ? pagamento.id : undefined,
				indPag: indicador,				
				tPag: pagamento.tPag,
				vPag: pagamento.vPag,										
			});
		}));

  return detPag
}