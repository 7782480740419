import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  IconButton,  
  Paper,
  TextField,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { Formik, Form, Field } from "formik";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import { DeleteOutline } from "@mui/icons-material";
import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
	},
  paperLogoImage: {
    padding: theme.spacing(2),
		alignItems: "center",
		marginBottom: 12,
  },
	margin: {
		margin: theme.spacing(1),
	},
  hiddenField: {
    display: "none",
  },
  logoImage: {
    width: "300px",
    height: "300px",
  },
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const initialState = {
		name: "",
		logo: "",
	};

  const [myProfile, setMyProfile] = useState(initialState);

  useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/empresa/1");
				setMyProfile(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);
  
  const handleChooseProfileLogo = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    profileLogoInput.click();
  }

  const handlerDrop = (e) => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const paperLogoImage = document.getElementById("paperLogoImage");
    const inputLogoImage = document.getElementById("inputLogoImage");
   
    const dT = new DataTransfer();

    profileLogoInput.files = e.dataTransfer.files;

    if (profileLogoInput.files[0].type !== "image/png"
      && profileLogoInput.files[0].type !== "image/gif"
      && profileLogoInput.files[0].type !== "image/jpeg") {
        profileLogo.value = "";
        profileLogoInput.value = "";
        inputLogoImage.src = "";
        paperLogoImage.style.display = "none";
        return;
    }

    dT.items.add(e.dataTransfer.files[0]);
    profileLogoInput.files = dT.files;
    profileLogo.value = profileLogoInput.files[0].name;
    inputLogoImage.src = URL.createObjectURL(profileLogoInput.files[0]);
    paperLogoImage.style.display = "block";

    e.preventDefault();
  }

  const handleRemoveLogoProfile = async () => {

    try {
      await api.put("/empresa/removeLogo/1");
      toast.success(i18n.t("myProfile.toasts.removeLogo"));
    }
    catch (err) {
      toastError(err);
    }

  }

  const handleRemoveLogoClick = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const inputLogoImage = document.getElementById("inputLogoImage");

    inputLogoImage.removeAttribute('src');
    profileLogo.value = "";
    profileLogoInput.value = "";
    inputLogoImage.src = "";

    handleRemoveLogoProfile();

  } 

  const handleChangeLogoClick = () => {
    document.getElementById("profileLogoInput").click();   
  } 

  const handleChangeLogo = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const paperLogoImage = document.getElementById("paperLogoImage");
    const inputLogoImage = document.getElementById("inputLogoImage");

    if (profileLogoInput.files[0].type !== "image/png"
      && profileLogoInput.files[0].type !== "image/gif"
      && profileLogoInput.files[0].type !== "image/jpeg") {
        profileLogo.value = "";
        profileLogoInput.value = "";
        inputLogoImage.src = "";
        paperLogoImage.style.display = "none";
        return;
    }
    
    profileLogo.value = profileLogoInput.files[0].name;
    inputLogoImage.src = URL.createObjectURL(profileLogoInput.files[0]);
    paperLogoImage.style.display = "block";
  }

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileName = document.getElementById("profileName").value;

    formData.append("logo", profileLogoInput.files[0]);
    formData.append("name", profileName);

    try {
      await api.put("/myProfile/1", formData);
      toast.success(i18n.t("myProfile.toasts.save"));
    }
    catch (err) {
      // toastError(err);
      toast.success("Erro ao salvar a imagem");
    }

  }

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
  };


  return (
    <MainContainer id="profileMainContainer" 
      className={classes.root} 
      onDrop={handlerDrop}
    >
      <MainHeader>
        <Title>{i18n.t("Empresa")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateProfile}
            className={classes.floatingButton}
          >
            {"Salvar"}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Container>
        <Formik
            initialValues={myProfile}
            enableReinitialize={true}
        >
          <Form>
            <Paper className={classes.paper}>
              <Field
                as={TextField}
                id="profileName"
                label={i18n.t("profile.labels.name")}
                autoFocus
                name="razaoSocial"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Paper>
            <div hidden="hidden">
              <Paper className={classes.paper}>
              
                <Field
                  as={TextField}
                  id="profileLogo"
                  helperText={i18n.t("profile.labels.logo")}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder={i18n.t("profile.labels.logoField")}
                  name="logoField"
                  InputProps={{ readOnly: true, }}
                  onClick={handleChooseProfileLogo}
                />
                  <input
                    name="logo"
                    className={classes.hiddenField}
                    margin="dense"
                    variant="outlined"
                    id="profileLogoInput"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleChangeLogo}
                  />
                
              </Paper>
            </div>
            <Paper 
              id="paperLogoImage"
              className={classes.paperLogoImage}
            >
              <center>
              <div 
                    variant="contained"
                    color="primary"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    onClick={handleChangeLogoClick}
                    display={myProfile.logoBase64 ? "none" : "block"}
                    >
                      {i18n.t("profile.labels.logoField")}  
                  </div>
                  <img 
                    id="inputLogoImage"
                    className={classes.logoImage}
                    
                    src={myProfile.logoBase64 ? myProfile.logoBase64 : null} 
                    onClick={handleChangeLogoClick}
                  />  
                  <br></br>
                  <br></br>

                  <ConfirmationModal
                    title={`${i18n.t("myProfile.confirmationModal.deleteTitle")}?`}
                    open={confirmModalOpen}
                    onClose={handleCloseConfirmationModal}
                    onConfirm={() => handleRemoveLogoClick()}
                  >
                    {i18n.t("myProfile.confirmationModal.deleteMessage")}
                  </ConfirmationModal>

                  <IconButton className={classes.icon}
                      size="small"
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline className={classes.actionButton} />
                    </IconButton>
                  
                  
              </center>
            </Paper>

          </Form>
        </Formik>
      </Container>
    </MainContainer>
  );
};

export default Profile;