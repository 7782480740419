import React, { useEffect, useState } from "react";

import "./style.css";
import * as Yup from 'yup';
import api from "../../services/api";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import toastError from "../../errors/toastError";
import MainHeaderFX from "../../components/MainHeaderFx";
import { AlignHorizontalCenter, Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControlLabel, Switch, TextField } from "@mui/material";

import {
  FlexFileField,
  FlexTextField,
  FlexSelectField,
  FlexFormatField
} from "../../components/input/Fields/FlexField";

import {
  Box,
  Button,
  Accordion,
  Container,
  Typography,
  DialogActions,
  useMediaQuery,
  AccordionDetails,
  AccordionSummary,
  IconButton, 
  InputAdornment, 
} from "@mui/material";

/*Imagens e ícones*/
import { i18n } from "../../translate/i18n";
import { Configuracao } from "../../configuracao";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PercentSharpIcon from "@mui/icons-material/PercentSharp";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CreditScoreSharpIcon from "@mui/icons-material/CreditScoreSharp";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { padding } from "@mui/system";

const EmpresaConfig = ({ isWizard, setStep }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const navigate = useNavigate(false);
  const arrayAmbiente = [{ ambiente: "Produção" }, { ambiente: "Homologação" }];
  const arrayOperacao = [
    { cfo: "01", descricao: "Revenda de mercadoria. (Comércio)" },
    { cfo: "02", descricao: "Revenda de mercadoria efetuada fora do estabelecimento. (Comércio)"},
    { cfo: "03", descricao: "Venda de produção própria (Indústria ou equiparada)"},
    { cfo: "04", descricao: "Venda de produção própria efetuada fora do estabelecimento. (Indústria ou equiparada)"}
  ];
  const arrayCsosn = [
    /*{ csosn: "101", descricao: "101 - Tributada pelo Simples Nacional com permissão de crédito"},*/
    { csosn: "102", descricao: "102 - Tributada pelo Simples Nacional sem permissão de crédito"},
    { csosn: "103", descricao: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta"},
    /*{ csosn: "201", descricao: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária"},
    { csosn: "202", descricao: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária"},
    { csosn: "203", descricao: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária"},*/
    { csosn: "300", descricao: "300 - Imune" },
    { csosn: "400", descricao: "400 - Não tributada pelo Simples Nacional" },
    { csosn: "500", descricao: "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação"},
    /*{ csosn: "900", descricao: "900 - Outros" },*/
  ];
  const arrayPisCofins = [
    { cst: "01", descricao: "01 - Operação Tributável com Alíquota Básica" },
    { cst: "02", descricao: "02 - Operação Tributável com Alíquota Diferenciada"},
    { cst: "03", descricao: "03 - Operação Tributável com Alíquota por Unidade de Medida de Produto"},
    { cst: "04", descricao: "04 - Operação Tributável Monofásica - Revenda a Alíquota Zero"},
    { cst: "05", descricao: "05 - Operação Tributável por Substituição Tributária"},
    { cst: "06", descricao: "06 - Operação Tributável a Alíquota Zero" },
    { cst: "07", descricao: "07 - Operação Isenta da Contribuição" },
    { cst: "08", descricao: "08 - Operação sem Incidência da Contribuição" },
    { cst: "09", descricao: "09 - Operação com Suspensão da Contribuição" },
    { cst: "49", descricao: "49 - Outras Operações de Saída" }
  ];

  const isSmallScreen = useMediaQuery("(max-width:825px)");

  const validationSchema = Yup.object().shape({
    configuracaoNfce: Yup.object().shape({
      idCsc: Yup.string().required('Campo obrigatório'),
      csc: Yup.string().required('Campo obrigatório'),
      ambiente: Yup.string().required('Campo obrigatório'),
    }),

    tributacao: Yup.object().shape({
      operacao: Yup.string().required('Campo obrigatório'),
      cstCsosnIcms: Yup.string().required('Campo obrigatório'),
      cstPisCofins: Yup.string().required('Campo obrigatório'),
      ncm: Yup.string().required('Campo obrigatório'),
    }),
  });

  
  const initialState = {
    configuracaoNfce: {
      ambiente: "",
      csc: "",
      idCsc: "",
      serie: "",
      ultimoNumero: -1,
      informarCPF: false,
    },
    certificado: {
      dataValidade: "",
      senha: "",
      hashArquivo: "",
      arquivoCertificado: null,
    },
    tributacao: {
      cest: "",
      operacao: "",
      cstCsosnIcms: "",
      cstPisCofins: "",
      ncm: "",
    },
  };

  const [configuracaoNfce, setConfiguracaoNfce] = useState(initialState);
  const [empresaId, setEmpresaId] = useState(-1);
  const [showPassword, setShowPassword] = useState(true)
  const [showPasswordStart, setShowPasswordStart] = useState(false)

  //  ***************
  //  **  Effects  **
  //  ***************
  useEffect(() => {
    (async () => {
      try {
        const { data: dataEmpresa } = await api.get("/v1/empresas");
        setEmpresaId(dataEmpresa.empresas[0].id);

        const { data } = await api.get(
          `/v1/empresas-configuracoes-payload/${dataEmpresa.empresas[0].id}`
        );
        console.log(data);
        setConfiguracaoNfce((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (error) {
        toastError(error);
      }
    })();

    return () => {
      setConfiguracaoNfce(initialState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  ***************
  //  ** Functions **
  //  ***************
  const handleBack = () => {
    navigate("/");
  };

  const onFocus = () => {
    document.getElementById("password").autoComplete = "off";
    if (!showPasswordStart)
    {
      setShowPasswordStart(true);
      document.getElementById("btnSenhaCertificado").click();
    }
  }

  const handleShowCertificadoForm = () => {
    document.getElementById("form-certificado").hidden=false;
    document.getElementById("btnShowCertificadoForm").hidden=true;
  };

  const handleHideCertificadoForm = () => {
    document.getElementById("form-certificado").hidden=true;
    document.getElementById("btnShowCertificadoForm").hidden=false;
  };

  const handleCsosnValidation = (values) => {
    if ((values.tributacao.operacao === "03" || values.tributacao.operacao === "04") && 
         values.tributacao.cstCsosnIcms === "500")
    {
      toast.error(i18n.t("empresaConfig.validations.csosnInvalid"));
      return false;
    }
      
    return true;
  };

  const handleSaveEmpresaConfig = async (values) => {
    try {

      if (!handleCsosnValidation(values))
        return false;

      const data = new FormData();
      if (document.getElementsByName("certificado.senha")[0]) {
        values.certificado.senha = document.getElementsByName("certificado.senha")[0].value;
      }
      data.set("JSON", JSON.stringify(values));
      data.append("arquivoCertificado", values.arquivoCertificado);
      
      if (empresaId) {
        await api.put(`/v1/empresas-configuracoes-payload/${empresaId}`, data);
      } else {
        await api.post(`/v1/empresas-configuracoes-payload`, data);
      }

      toast.success(i18n.t("toasts.saved"));

      if (isWizard){
        setStep(prevState => prevState + 1)
      } else {
        setTimeout(function () {
          handleBack();
        }, 1400);
      }
    } catch (error) {
      toastError(error);
    }
  };

  //  ***************
  //  **  Return   **
  //  ***************
  return (
    
    <Container className="components">
      <Formik
        initialValues={configuracaoNfce}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveEmpresaConfig(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form style={{display: "flex", 
                        flexDirection: "column", 
                        height: !isWizard ? "100vh" : "100%", 
                        gap:"1em"}}
          >
            {!isWizard && (
              <div style={{height: "10%"}}>
                <MainHeaderFX
                  title="Configurações" 
                  titleButton={i18n.t("button.add")}
                  leftContainerType={isSmallScreen ? "page" : "hidden"}
                  rightContainerType={isSmallScreen ? "modal" : "hidden"}
                  backParam={"/"}
                  handleSaveModal={() => handleSaveEmpresaConfig(values)}
                />
              </div>
            )}

            <Container style={{height: isSmallScreen ? "90%" : "81%", overflowY: "auto"}}>
              <Box mb={2} />
              <Accordion
                style={{ backgroundColor: Configuracao.COR_6 }}
                defaultExpanded={!isSmallScreen && !isWizard}
              >
               
                <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                  <Typography className="iconTypografy" variant="h7">
                    <Box mr={2}>
                      <VpnKeyIcon style={{ textAlign: "center" }} />
                    </Box>
                    {i18n.t("empresaConfig.csc.title")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className="gerenciamentoCSC">
                    <FlexTextField
                      label={i18n.t("empresaConfig.csc.csc")}
                      name="configuracaoNfce.idCsc"
                      error={touched.configuracaoNfce && !!errors.configuracaoNfce?.idCsc}
                      helperText={touched.configuracaoNfce && errors.configuracaoNfce?.idCsc}
                      width="15%"
                    />
                    <FlexTextField
                      label={i18n.t("empresaConfig.csc.codigoCsc")}
                      name="configuracaoNfce.csc"
                      error={touched.configuracaoNfce && !!errors.configuracaoNfce?.csc}
                      helperText={touched.configuracaoNfce && errors.configuracaoNfce?.csc}
                      width="60%"
                    />
                    <FlexSelectField
                      label={i18n.t("empresaConfig.csc.ambiente")}
                      name="configuracaoNfce.ambiente"
                      error={touched.configuracaoNfce && !!errors.configuracaoNfce?.ambiente}
                      helperText={touched.configuracaoNfce && errors.configuracaoNfce?.ambiente}
                      arrayList={arrayAmbiente}
                      propArray={"ambiente"}
                      propArrayId={"ambiente"}
                      width="20%"
                      marginTop="7px"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                style={{ backgroundColor: Configuracao.COR_6 }}
                defaultExpanded={!isSmallScreen && !isWizard}
              >
                <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                  <Typography className="iconTypografy" variant="h7">
                    <Box mr={2}>
                      <ListAltOutlinedIcon style={{ textAlign: "center" }} />
                    </Box>
                    {i18n.t("empresaConfig.nfce.title")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className="gerenciamentoCSC">
                    <FlexTextField
                        label={i18n.t("empresaConfig.nfce.serie")}
                        name="configuracaoNfce.serie"
                        width="15%"
                      />
                      <FlexTextField
                        label={i18n.t("empresaConfig.nfce.ultimoNumero")}
                        name="configuracaoNfce.ultimoNumero"
                        width="15%"
                      />
                      <br></br>
                      <FormControlLabel
                        style = {{paddingLeft: isSmallScreen ? "0px" : "20px",
                                  paddingTop: isSmallScreen ? "5px" : "18px"}}
                        control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="configuracaoNfce.informarCPF"
                          checked={values.configuracaoNfce.informarCPF}
                        />
                      } label = {i18n.t("empresaConfig.nfce.informarCpf")}
                      />
                  </div>
                </AccordionDetails>
              </Accordion>  

              <Accordion
                style={{ backgroundColor: Configuracao.COR_6 }}
                defaultExpanded={false}
              >
                <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                  <Typography className="iconTypografy" variant="h7">
                    <Box mr={2}>
                      <CreditScoreSharpIcon style={{ textAlign: "center" }} />
                    </Box>
                    {i18n.t("empresaConfig.certificado.title")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>          
                  <div id="btnShowCertificadoForm" hidden={(!configuracaoNfce.certificado.hashArquivo ? true : false)}>
                  <label style={{paddingRight: "10px"}}>{i18n.t("empresaConfig.certificado.certificado") + ": " + (configuracaoNfce.certificado.hashArquivo ? configuracaoNfce.certificado.hashArquivo : "Nenhum")}</label>
                    <br></br>
                    <FlexTextField
                        label={i18n.t("empresaConfig.certificado.dataValidade")}
                        name="certificado.dataValidade"
                        type="date"
                        width="25%"
                        disabled={true}
                      /><br></br>
                      <Button
                        onClick={handleShowCertificadoForm}
                        variant="outlined"
                        color="primary"
                      >
                        {i18n.t("button.replace")}
                    </Button>
                  </div>

                  <div id="form-certificado" hidden={(configuracaoNfce.certificado.hashArquivo ? true : false)}>
                    <div className="gerenciamentoCSC">          
                      <FlexFileField
                        label={i18n.t("empresaConfig.certificado.certificado")}
                        name="arquivoCertificado"
                        width="60%"
                        setFieldValue={setFieldValue}   
                      />
                      <TextField
                        label={i18n.t("empresaConfig.certificado.hashSenha")}
                        name="certificado.senha"
                        margin="normal"
                        id="password"     
                        width="40%"
                        autoComplete="off"
                        fullWidth={isSmallScreen}  
                        onFocus={onFocus}   
                        style={{ marginTop: "7px" }}
                        type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton id="btnSenhaCertificado"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword((e) => !e)}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        /> 
                    </div>

                    <div hidden={(!configuracaoNfce.certificado.hashArquivo ? true : false)}>        
                      <div className="dataCompany">
                        <Button
                            onClick={handleHideCertificadoForm}
                            variant="outlined"
                            color="primary"
                          >
                            {i18n.t("button.cancel")}
                        </Button>
                      </div>
                    </div>  
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                style={{ backgroundColor: Configuracao.COR_6 }}
                defaultExpanded={false}
              >
                <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                  <Typography className="iconTypografy" variant="h7">
                    <Box mr={2}>
                      <PercentSharpIcon style={{ textAlign: "center" }} />
                    </Box>
                    {i18n.t("empresaConfig.tributacaoPadrao.title")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Container className="dataTributacao">
                    <FlexSelectField
                      label={i18n.t("empresaConfig.tributacaoPadrao.operacao")}
                      name="tributacao.operacao"
                      error={touched.tributacao && !!errors.tributacao?.operacao}
                      helperText={touched.tributacao && errors.tributacao?.operacao}
                      arrayList={arrayOperacao}
                      propArray={"descricao"}
                      propArrayId={"cfo"}
                      width="100%"
                    />
                    <FlexSelectField
                      label={i18n.t("empresaConfig.tributacaoPadrao.cstCsosnIcms")}
                      name="tributacao.cstCsosnIcms"
                      error={touched.tributacao && !!errors.tributacao?.cstCsosnIcms}
                      helperText={touched.tributacao && errors.tributacao?.cstCsosnIcms}
                      arrayList={arrayCsosn}
                      propArray={"descricao"}
                      propArrayId={"csosn"}
                      width="100%"
                    />
                    <FlexSelectField
                      label={i18n.t("empresaConfig.tributacaoPadrao.cstPisCofins")}
                      name="tributacao.cstPisCofins"
                      error={touched.tributacao && !!errors.tributacao?.cstPisCofins}
                      helperText={touched.tributacao && errors.tributacao?.cstPisCofins}
                      arrayList={arrayPisCofins}
                      propArray={"descricao"}
                      propArrayId={"cst"}
                      width="100%"
                    />
                    <FlexFormatField
                      label={i18n.t("empresaConfig.tributacaoPadrao.ncm")}
                      name="tributacao.ncm"
                      error={touched.tributacao && !!errors.tributacao?.ncm}
                      helperText={touched.tributacao && errors.tributacao?.ncm}
                      width="20%"
                      inputMode="numeric"
                      format="####.##.##"
                      allowEmptyFormatting={false}
                    />
                    {values.tributacao.cstCsosnIcms === "500" && (
                      <FlexFormatField
                        label={i18n.t("empresaConfig.tributacaoPadrao.cest")}
                        name="tributacao.cest"
                        width="20%"
                        inputMode="numeric"
                        format="##.###.##"
                      />
                    )}
                  </Container>
                </AccordionDetails>
              </Accordion> 
            </Container>

                <Container style={{height: "6%"}}>
                  <DialogActions>
                    {!isSmallScreen && !isWizard && (
                      <Button
                        onClick={handleBack}
                        color="inherit"
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        {i18n.t("button.cancel")}
                      </Button>
                    )}

                    <Button
                      id="empresaConfig_save"
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      style={{visibility: isWizard?"hidden":"visible"}}
                    >
                      {i18n.t("button.save")}
                    </Button>
                  </DialogActions>
                </Container>
            
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EmpresaConfig;
