import React, { useEffect, useReducer, useState } from "react";

import { Table, 
  TableContainer, 
  TableRow, 
  TableCell, 
  TableBody, 
  Container, 
  SpeedDial, 
  SpeedDialIcon,
  Paper,  
  useMediaQuery
} from "@mui/material";

import "./style.css";
import api from "../../services/api.js";
import toastError from "../../errors/toastError.js";
import { Configuracao } from "../../configuracao/index.js";
import MainContainer from "../../components/MainContainer/index.js";
import TableRowSkeleton from "../../components/TableRowSkeleton/index.js";
import UserModal from "../../components/UserModal"
import MainHeaderFx from "../../components/MainHeaderFx"

/*Imagens e ícones*/
import naoEncontrado from "../../assets/naoEncontrado.svg"
import { i18n } from "../../translate/i18n.js";

const reducer = (state, action) => {  
  
  if (action.type === "LOAD_USER") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });   

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USER") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  } 
};

const Users = () => {
  const [user, dispatch] = useReducer(reducer, []);
  
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [search, setSearch] = useState("");
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const isSmallScreen = useMediaQuery('(max-width:825px)');

  const handleOpenEditModal = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };
  
  const handleModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  }

  const loadMore = () => {   
    if (isScroll) {
      setPage((prevState) => prevState + 1);
      setIsScroll(false);
    }    
  };

  const handleScroll = (e) => {  
    if (!hasMore || loading) return;    
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 1) < clientHeight) {           
      loadMore();      
    }
  }; 

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPage(1);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {             
          const { data } = await api.get("/v1/users/", {
            params: { search, page },
          }); 
          setIsScroll(true);
          dispatch({ type: "LOAD_USER", payload: data.users});
          setHasMore(data.hasMore);
          setTotalCount(data.count);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);    

  return (
    <div className="pageRoot">
      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="form-dialog-title"
        userId={selectedUser && selectedUser.id}
        users={user}
      />

      <MainHeaderFx
        title={i18n.t("user.pluralTitle")}
        titleButton={i18n.t("button.add")}
        leftContainerType={"page"}
        rightContainerType={"search"}
        backParam={"/"}
        search={search}
        setSearch={setSearch}
        handleModal={handleModal}
      />

      <MainContainer>
        <Paper className="scroll"
          onScroll={handleScroll}          
        >        
          <TableContainer>        
            <Table>
            <TableBody >
                {user.map((user, index) => (                        
                  <TableRow 
                    key={index}
                    onClick={() => handleOpenEditModal(user)}
                  >                
                    <TableCell> {user.name} </TableCell>
                    <TableCell> {user.email} </TableCell>                  
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={4} />}
              </TableBody>
            </Table>
          </TableContainer>
              
          {totalCount === 0 && !loading && (
            <Container className="record">
              <img alt="consulta" 
                id="logoTipo"                         
                style={{ width: "70%", height: "30%" }} 
                src={naoEncontrado}
              />     
              <br/>
              <span style={{fontSize: "1em", fontWeight:"bold", opacity: 0.85}} >{Configuracao.LISTAGEM_VAZIA} </span>
            </Container>
          )}

          {isSmallScreen && (
            <SpeedDial className="iconAdd"             
              ariaLabel="SpeedDial basic example"
              onClick={handleModal}
              icon={<SpeedDialIcon/>}
            /> 
          )}
        </Paper>      
      </MainContainer>      
    </div>
  );
};

export default Users;